import { Box, Typography } from '@mui/material';
import { PaymentMethodForm } from './PaymentMethodForm';
import {
  ContractData,
  DebtProducts,
  ICoupon,
  ICouponDistributed,
} from 'recaudo-common';
import { Dispatch, SetStateAction } from 'react';
import {
  CustomPaymentType,
  PaymentType,
} from '../utils/enums/payment-form.enum';
import { UseMutationResult } from '@tanstack/react-query';

interface ContractPaymentSectionProps {
  debtData: DebtProducts | null;
  paymentType: PaymentType | null;
  setPaymentType: Dispatch<SetStateAction<PaymentType | null>>;
  distributionMethod: CustomPaymentType | null;
  setDistributionMethod: Dispatch<SetStateAction<CustomPaymentType | null>>;
  contract: ContractData | null;
  getCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponId: number; contractId?: number },
    unknown
  >;
  createDistributedCouponMutation: UseMutationResult<
    ICouponDistributed,
    unknown,
    { couponValue: number; contractId: number },
    unknown
  >;
  createBrillaCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponValue: number; contractId: number; productId: number },
    unknown
  >;
}

export const ContractPaymentSectionComponent = (
  props: ContractPaymentSectionProps
) => {
  const {
    debtData,
    paymentType,
    setPaymentType,
    contract,
    getCouponMutation,
    createDistributedCouponMutation,
    createBrillaCouponMutation,
    distributionMethod,
    setDistributionMethod,
  } = props;

  return (
    <Box
      sx={{
        margin: '25px 0',
        paddingBottom: '10px',
        borderBottom: '1px solid #eeeeee',
      }}
    >
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        Método de abono
      </Typography>
      <Typography paragraph sx={{ fontSize: '14px' }}>
        Seleccione el método de abono que el cliente prefiere para iniciar con
        el proceso de pago.
      </Typography>
      <PaymentMethodForm
        debtData={debtData}
        paymentType={paymentType}
        setPaymentType={setPaymentType}
        contract={contract}
        getCouponMutation={getCouponMutation}
        createDistributedCouponMutation={createDistributedCouponMutation}
        createBrillaCouponMutation={createBrillaCouponMutation}
        distributionMethod={distributionMethod}
        setDistributionMethod={setDistributionMethod}
      />
    </Box>
  );
};
