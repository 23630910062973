import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Skeleton,
  SvgIcon,
  Typography,
} from '@mui/material';
import {
  CouponStatusEnum,
  downloadPDF,
  ICoupon,
  ICouponDistributed,
  moneyWithDots,
  ProductType,
  saveAsPdf,
} from 'recaudo-common';
import theme from 'ui/styles/theme';
import {
  CustomPaymentType,
  PaymentType,
} from '../utils/enums/payment-form.enum';
import { Circle } from '@mui/icons-material';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { getCouponPdf } from '../http/api/coupons';
import { formatPercentage } from '../utils/functions/format';

interface CouponCardProps {
  getCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponId: number; contractId?: number },
    unknown
  >;
  createDistributedCouponMutation: UseMutationResult<
    ICouponDistributed,
    unknown,
    { couponValue: number; contractId: number },
    unknown
  >;
  createBrillaCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponValue: number; contractId: number; productId: number },
    unknown
  >;
  paymentType: PaymentType | null;
  loading: boolean;
  distributionMethod: CustomPaymentType | null;
}

const StatusColor: Record<CouponStatusEnum, string> = {
  [CouponStatusEnum.PAID]: '#18cc48',
  [CouponStatusEnum.PENDING]: '#DAA71A',
};

const StatusText: Record<CouponStatusEnum, string> = {
  [CouponStatusEnum.PAID]: 'Pagado',
  [CouponStatusEnum.PENDING]: 'Por pagar',
};

const DistributionText: Record<CustomPaymentType, string> = {
  [CustomPaymentType.Brilla]: 'Abono solo a Brilla',
  [CustomPaymentType.Distributed]:
    'El valor se distribuirá entre los productos con deuda de la siguiente manera',
};

export const CouponCard = (props: CouponCardProps) => {
  const {
    paymentType,
    distributionMethod,
    loading,
    getCouponMutation,
    createBrillaCouponMutation,
    createDistributedCouponMutation,
  } = props;

  const loadingCoupon =
    loading ||
    (!getCouponMutation.data &&
      !createBrillaCouponMutation.data &&
      !createDistributedCouponMutation.data);

  const actualCoupon =
    paymentType === PaymentType.Coupon
      ? getCouponMutation.data
      : distributionMethod === CustomPaymentType.Brilla
        ? createBrillaCouponMutation.data
        : createDistributedCouponMutation.data;

  const renderHeader = () => {
    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '25%' }} />;
    }

    return (
      <Typography sx={{ color: '#333333', fontWeight: 600, fontSize: 12 }}>
        Nº Cupón <span style={styles.subText}>{actualCoupon!.couponId}</span>
      </Typography>
    );
  };

  const renderHeaderStatus = () => {
    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '25%' }} />;
    }

    const statusColor = StatusColor[actualCoupon!.status];

    return (
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: '#333333',
          fontWeight: 600,
        }}
      >
        <SvgIcon
          sx={{
            fontSize: 6,
            marginRight: theme.spacing(2),
            color: statusColor,
          }}
        >
          <Circle />
        </SvgIcon>
        <span
          style={{ ...styles.subText, color: statusColor, fontWeight: 600 }}
        >
          {StatusText[actualCoupon!.status]}
        </span>
      </Typography>
    );
  };

  const renderAddress = () => {
    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '75%' }} />;
    }

    return (
      <Typography
        sx={{
          ...styles.subtitle,
          fontWeight: 700,
          marginBottom: theme.spacing(1),
        }}
      >
        {actualCoupon!.city} {actualCoupon!.address}
      </Typography>
    );
  };

  const renderContract = () => {
    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '50%' }} />;
    }

    return (
      <Typography sx={styles.subtitle}>
        Nº Contrato asociado{' '}
        <span style={{ ...styles.subText, marginBottom: theme.spacing(1) }}>
          {actualCoupon!.contract}
        </span>
      </Typography>
    );
  };

  const renderAmountDistribution = () => {
    if (paymentType !== PaymentType.Custom) {
      return null;
    }

    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '25%' }} />;
    }

    const distributionText =
      DistributionText[distributionMethod || CustomPaymentType.Brilla];

    return (
      <Typography sx={{ ...styles.subtitle, marginBottom: theme.spacing(1) }}>
        Distribución del monto{' '}
        <span style={{ ...styles.subText }}>{distributionText}</span>
      </Typography>
    );
  };

  const renderProducts = () => {
    if (paymentType !== PaymentType.Custom) {
      return null;
    }

    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 12, width: '25%' }} />;
    }

    if (distributionMethod === CustomPaymentType.Brilla) {
      return (
        <Box display="flex" flexDirection="column">
          <Typography sx={styles.subtitle}>
            Producto{' '}
            <span style={{ ...styles.subText }}>
              {' '}
              Brilla - {createBrillaCouponMutation.variables?.productId}
            </span>
          </Typography>
        </Box>
      );
    }

    if (distributionMethod === CustomPaymentType.Distributed) {
      const distributedCouponData = actualCoupon as ICouponDistributed;
      const products = distributedCouponData.products;
      const gasProduct = products.find(
        (product) => product.productType === ProductType.Gas
      );
      const brillaProduct = products.find(
        (product) => product.productType === ProductType.FinancingServices
      );
      const insuranceProduct = products.find(
        (product) => product.productType === ProductType.BrillaInsurancesA
      );

      const productsCount = [
        gasProduct,
        brillaProduct,
        insuranceProduct,
      ].filter(Boolean).length;

      const distribution = productsCount === 3 ? 4 : 6;

      return (
        <Box display="flex" flexDirection="column">
          <Typography sx={styles.subtitle}>Productos </Typography>
          <Grid container justifyContent="space-between">
            {gasProduct && (
              <Grid item xs={distribution}>
                <Typography sx={styles.subText}>
                  Gas - {gasProduct.productId}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {formatPercentage(gasProduct.percentage)} ·{' '}
                  {moneyWithDots(gasProduct.value)}
                </Typography>
              </Grid>
            )}
            {brillaProduct && (
              <Grid item xs={distribution}>
                <Typography sx={styles.subText}>
                  Brilla - {brillaProduct.productId}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {formatPercentage(brillaProduct.percentage)} ·{' '}
                  {moneyWithDots(brillaProduct.value)}
                </Typography>
              </Grid>
            )}
            {insuranceProduct && (
              <Grid item xs={distribution}>
                <Typography sx={styles.subText}>
                  Seguro Brilla - {insuranceProduct.productId}
                </Typography>
                <Typography sx={styles.subtitle}>
                  {formatPercentage(insuranceProduct.percentage)} ·{' '}
                  {moneyWithDots(insuranceProduct.value)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      );
    }
  };

  const renderTotalValue = () => {
    if (loadingCoupon) {
      return <Skeleton variant="text" sx={{ fontSize: 14, width: '25%' }} />;
    }

    return (
      <Typography sx={{ fontSize: 12, color: '#333' }}>
        Total a pagar{' '}
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          {moneyWithDots(actualCoupon!.value)}
        </span>
      </Typography>
    );
  };

  const getPdfCouponMutation = useMutation({
    mutationFn: getCouponPdf,
  });

  const handlePdfDownload = async () => {
    const couponId = actualCoupon!.couponId;
    const base64 = await getPdfCouponMutation.mutateAsync(couponId);

    const blob = downloadPDF(base64);
    saveAsPdf(blob, `${couponId}.pdf`);
  };

  return (
    <Card
      sx={{
        width: '100%',
        backgroundColor: '#F2F2F2',
        border: '1px solid',
        borderColor: '#DBDBDB',
        fontSize: 12,
        color: '#707070',
        borderRadius: 6,
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: '#F2F2F2',
          padding: theme.spacing(4),
        }}
      >
        {renderHeader()}
        {renderHeaderStatus()}
      </Box>
      <Box sx={{ backgroundColor: '#F8F8F8' }}>
        <Box sx={{ padding: theme.spacing(4) }}>
          {renderAddress()}
          {renderContract()}
          {renderAmountDistribution()}
          {renderProducts()}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.spacing(4),
        }}
      >
        {renderTotalValue()}
        {!loadingCoupon && (
          <Button
            variant="outlined"
            sx={{ fontSize: 12, textTransform: 'none', fontWeight: 600 }}
            onClick={handlePdfDownload}
          >
            {getPdfCouponMutation.isPending ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              'Descargar cupón'
            )}
          </Button>
        )}
      </Box>
    </Card>
  );
};

const styles = {
  subtitle: {
    fontWeight: 600,
    fontSize: 12,
  },
  subText: {
    fontWeight: 400,
    color: '#707070',
    fontSize: 12,
  },
};
