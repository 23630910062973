import { TransactionType } from 'recaudo-common';
import { CustomPaymentType, PaymentType } from '../enums/payment-form.enum';

export const isPrintableAscii = (str: string | undefined): boolean => {
  if (typeof str !== 'string') {
    return false;
  }
  return /^[ -~]+$/.test(str);
};

export const selectDistributionType = (
  paymentType: string | null,
  distributionMethod: string | null
) => {
  if (paymentType === PaymentType.Coupon) {
    return TransactionType.Regular;
  }
  if (distributionMethod === CustomPaymentType.Brilla) {
    return TransactionType.Brilla;
  }
  if (distributionMethod === CustomPaymentType.Distributed) {
    return TransactionType.Proportional;
  }
  return '';
};
