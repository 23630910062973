import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import { UseMutationResult } from '@tanstack/react-query';
import {
  calculateTotalCurrentDebts,
  ContractData,
  DebtProducts,
  ProductDebtsApi,
} from 'recaudo-common';
import toast from 'react-hot-toast';

interface ContractQueryProps {
  resetContractData: () => void;
  setResponseContractData: (data: ContractData) => void;
  setResponseDebtsData: (data: DebtProducts) => void;
  callGetContractData: UseMutationResult<
    { data: ContractData },
    Error,
    number,
    unknown
  >;
  callGetDebts: UseMutationResult<
    { data: ProductDebtsApi },
    Error,
    number,
    unknown
  >;
}

const contractSchema = yup.object({
  contractNumber: yup
    .string()
    .typeError('Digite un número de contrato válido')
    .required('El número de contrato es requerido'),
});

export const ContractQuerySectionComponent = (props: ContractQueryProps) => {
  const {
    resetContractData,
    callGetContractData,
    callGetDebts,
    setResponseContractData,
    setResponseDebtsData,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ contractNumber: string }>({
    resolver: yupResolver(contractSchema),
    defaultValues: {
      contractNumber: '',
    },
    mode: 'all',
  });

  /** Ejecutar llamado de las APIs de consulta */
  const queryContractData = async (data: { contractNumber: string }) => {
    resetContractData();

    const reponseContractData = await callGetContractData.mutateAsync(
      Number(data.contractNumber)
    );
    setResponseContractData(reponseContractData?.data);

    const responseDebts = await callGetDebts.mutateAsync(
      Number(data.contractNumber)
    );

    const productsDebtData = [...responseDebts?.data?.products];
    setResponseDebtsData(productsDebtData);

    if (productsDebtData) {
      const currentValue = calculateTotalCurrentDebts(productsDebtData);
      if (currentValue === 0) {
        toast.success('La deuda corriente del contrato se encuentra al día', {
          duration: 5000,
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(queryContractData)}>
      <Grid item xs={12} sx={styles.gridSection}>
        <Box>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            Consulta de contrato <br />
          </Typography>
          <Typography sx={{ fontSize: '14px' }}>
            Ingrese el número de contrato del cliente en la línea para ver el
            detalle de su deuda. <br /> <br />
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ flexWrap: 'nowrap', paddingBottom: '10px' }}
          >
            <Grid item xs={12} sm={12}>
              <ControlledTextInput
                label="Número de contrato"
                name="contractNumber"
                control={control}
                type="number"
                errors={errors.contractNumber}
                isDirty={Boolean(errors?.contractNumber)}
                inputsProps={{
                  sx: {
                    background: '#fff',
                    width: '100%',
                  },
                }}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Button
                variant="outlined"
                onSubmit={handleSubmit(queryContractData)}
                sx={styles.buttonSubmit}
                type="submit"
                disabled={
                  callGetDebts?.isPending || callGetContractData?.isPending
                }
              >
                {callGetDebts?.isPending || callGetContractData?.isPending ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  'Buscar'
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </form>
  );
};

// styles.js
export const styles = {
  buttonSubmit: {
    height: 55,
    width: 'auto',
    minWidth: 100,
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
  },
  gridSection: {
    height: 'auto',
    paddingBottom: '10px',
    borderBottom: '1px solid #eeeeee',
  },
};
