import { Box, Button, CircularProgress, Grid } from '@mui/material';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { ICoupon, ContractData } from 'recaudo-common';
import { UseMutationResult } from '@tanstack/react-query';

const couponSchema = yup.object({
  couponId: yup
    .string()
    .typeError('Digite un cupón válido')
    .matches(/^\d+$/, 'Solo números')
    .test('len', 'Digite un cupón válido', (value) => {
      const strValue = value ? value.toString() : '';
      return strValue.length >= 7;
    })
    .required('Es requerido'),
});

interface CouponQueryFormProps {
  getCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponId: number; contractId?: number },
    unknown
  >;
  contract: ContractData | null;
}

export const CouponQueryForm = (props: CouponQueryFormProps) => {
  const { getCouponMutation, contract } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ couponId: string }>({
    resolver: yupResolver(couponSchema),
    defaultValues: {
      couponId: '',
    },
    mode: 'all',
  });

  const onSubmit = async (data: { couponId: string }) => {
    await getCouponMutation.mutateAsync({
      couponId: Number(data.couponId),
      contractId: contract?.contractId,
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: 'auto' }}>
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          <Grid item xs={12}>
            <ControlledTextInput
              label="Número de cupón"
              name="couponId"
              control={control}
              isDirty={Boolean(errors?.couponId)}
              type="text"
              inputsProps={{
                sx: {
                  width: '100%',
                  background: '#fff',
                },
              }}
              errors={errors.couponId}
              autoFocus
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              onSubmit={handleSubmit(onSubmit)}
              sx={styles.buttonSubmit}
              type="submit"
              disabled={getCouponMutation.isPending}
            >
              {getCouponMutation.isPending ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                'Buscar'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const styles = {
  buttonSubmit: {
    height: 55,
    minWidth: 100,
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
  },
};
