import { Box, Typography, Skeleton } from '@mui/material';
import { ContractData } from 'recaudo-common';

interface ContractDataProps {
  contractData: ContractData | null;
  loading: boolean;
}

export const ContractDataSectionComponent = (props: ContractDataProps) => {
  const { loading, contractData } = props;

  return (
    <>
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        Información del contrato <br />
      </Typography>
      <Box
        p={2}
        height="144px"
        borderRadius={4}
        bgcolor="#F8F8F8"
        padding={5}
        marginTop={5}
        border="1px solid #DBDBDB"
      >
        <Box marginBottom="16px">
          <Typography sx={{ fontSize: '12px', color: 'gray' }}>
            Información del contrato <br />
          </Typography>
          {loading ? (
            <>
              <Skeleton variant="text" sx={styles.skeleton} />
            </>
          ) : (
            <Typography sx={styles.subText}>
              {(contractData?.location && `${contractData?.location},`) || ''}
              {contractData?.address || ''} <br />
            </Typography>
          )}
        </Box>

        <Box>
          <Typography sx={{ fontSize: '12px', color: 'gray' }}>
            Nombre del titular <br />
          </Typography>
          {loading ? (
            <Skeleton variant="text" sx={styles.skeleton} />
          ) : (
            <Typography sx={styles.subText}>
              {contractData?.holderName || ''} <br />
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const styles = {
  subText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  skeleton: {
    fontSize: '16px',
    width: '50%',
  },
};
