import {
  ICoupon,
  ICouponDistributed,
  IRequestPaymentNequiData,
  IRequestPaymentPseData,
} from 'recaudo-common';
import api from '../api';

export const getCoupon = async ({
  couponId,
  contractId,
}: {
  couponId: number;
  contractId?: number;
}) => {
  const response = await api.post<ICoupon>('/coupons', {
    couponId,
    contractId,
  });

  return response.data;
};

export const createBrillaCoupon = async ({
  productId,
  couponValue,
  contractId,
}: {
  productId: number;
  couponValue: number;
  contractId: number;
}) => {
  const response = await api.post<ICoupon>('/coupons/createBrillaCoupon', {
    productId,
    couponValue,
    contractId,
  });

  return response.data;
};

export const createDistributedCoupon = async ({
  couponValue,
  contractId,
}: {
  couponValue: number;
  contractId: number;
}) => {
  const response = await api.post<ICouponDistributed>(
    '/coupons/createDistributedCoupon',
    {
      couponValue,
      contractId,
    }
  );

  return response.data;
};

export const getCouponPdf = async (couponId: number) => {
  const response = await api.post<string>(`/coupons/pdf/${couponId}`);
  return response.data;
};

export const nequiPayment = async (requestData: IRequestPaymentNequiData) => {
  return await api.post<string>('/payments/nequi', requestData);
};

export const psePayment = async (requestData: IRequestPaymentPseData) => {
  return await api.post<string>('/payments/pse', requestData);
};
