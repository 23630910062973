import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import ControlledSelectInput from 'ui/components/inputs/controlled-select-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  IdentificationType,
  ClientType,
  Gateway,
  ICoupon,
  ICouponDistributed,
} from 'recaudo-common';
import { useMutation, useQuery } from '@tanstack/react-query';
import theme from '../../styles/theme';
import {
  isPrintableAscii,
  selectDistributionType,
} from '../../utils/functions';
import FlagColombia from '../CustomComponents/CustomIcons/FlagColombia';
import { NotificationModal } from '../NotificationModal';
import { getBankList } from '../../http/api/gateway';
import { psePayment } from '../../http/api/coupons';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

export const pseInfoSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo electrónico válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .nullable()
    .required('Digite un correo electrónico válido'),
  fullName: yup
    .string()
    .trim()
    .nullable()
    .required('Digite el nombre del pagador'),
  identification: yup
    .string()
    .typeError('Digite una identificación válida')
    .matches(/^\d+$/, 'Solo números')
    .test('len', 'Digite una identificación válida', (value) => {
      const strValue = value ? value.toString() : '';
      return strValue.length >= 6 && strValue.length <= 10;
    })
    .required('Digite un número de identificación'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .nullable()
    .required('Digite un teléfono válido'),
  phoneCountryCode: yup
    .string()
    .trim()
    .nullable()
    .required('Digite un identificador de pais válido'),
  financialInstitutionCode: yup
    .string()
    .trim()
    .nullable()
    .required('Seleccione una opción válida'),
  clientType: yup
    .string()
    .trim()
    .nullable()
    .required('Seleccione una opción válida'),
  identificationType: yup
    .string()
    .trim()
    .nullable()
    .required('Seleccione una opción válida'),
});

interface PaymentPseFormProps {
  handleModalstate: (value?: boolean) => void;
  modalState: boolean;
  distributionMethod: string | null;
  paymentType: string | null;
  resetViewData: () => void;
  couponData?: ICoupon | ICouponDistributed;
}

interface PseForm {
  email: string;
  fullName: string;
  clientType: string;
  financialInstitutionCode: string;
  phone: string;
  phoneCountryCode: string;
  identification: string;
  identificationType: string;
}

export const PaymentPseForm = (props: PaymentPseFormProps) => {
  const {
    handleModalstate,
    modalState,
    resetViewData,
    distributionMethod,
    couponData,
    paymentType,
  } = props;

  const [disabledButtonLink, setDisabledButtonLink] = useState(true);

  const { data: bankData, isLoading: isBanksLoading } = useQuery({
    queryKey: ['getBankList'],
    queryFn: getBankList,
  });

  const payCouponMutation = useMutation({
    mutationFn: psePayment,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(pseInfoSchema),
    mode: 'all',
    defaultValues: {
      phoneCountryCode: '57',
    },
  });

  const {
    email,
    clientType,
    financialInstitutionCode,
    identificationType,
    identification,
    fullName,
    phoneCountryCode,
    phone,
  } = watch();

  const validateOptions = [
    { label: 'Persona natural', value: ClientType.NaturalPerson },
    { label: 'Persona juridica', value: ClientType.LegalPerson },
  ];

  const identificationTypeOptions = [
    { label: 'Cédula de ciudadanía', value: IdentificationType.CC },
    { label: 'NIT', value: IdentificationType.NIT },
    { label: 'Cédula de extranjería', value: IdentificationType.CE },
  ];

  const bankOptions =
    isBanksLoading || !bankData
      ? []
      : bankData?.data?.map((bank) => {
          return {
            label: bank.description,
            value: bank.pseCode,
          };
        });

  const onSubmit = async (data: PseForm) => {
    const financialInstitutionName = bankOptions.find(
      (bank) => bank.value === data.financialInstitutionCode
    );

    if (!couponData) {
      toast.error('No se encontró información del cupón');
      return;
    }

    const response = await payCouponMutation.mutateAsync({
      ...data,
      financialInstitutionName: financialInstitutionName?.label || '',
      type: selectDistributionType(paymentType, distributionMethod),
      contractId: couponData?.contract,
      couponId: couponData?.couponId,
      value: couponData?.value,
    });

    if (response) {
      handleModalstate(true);
    }
  };

  useEffect(() => {
    if (
      !email ||
      !clientType ||
      !financialInstitutionCode ||
      !identificationType ||
      !identification ||
      !fullName ||
      !phoneCountryCode ||
      !phone
    ) {
      setDisabledButtonLink(true);
      return;
    }
    setDisabledButtonLink(false);
  }, [
    email,
    clientType,
    financialInstitutionCode,
    identificationType,
    identification,
    fullName,
    phoneCountryCode,
    phone,
    setDisabledButtonLink,
  ]);

  const resetData = () => {
    resetViewData();
    reset();
    handleModalstate(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} sx={styles.fieldsContainer}>
          <Grid item xs={12}>
            <ControlledTextInput
              label="Correo electrónico"
              name="email"
              control={control}
              isDirty={Boolean(errors?.email)}
              type="text"
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.email}
              autoFocus
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={styles.fieldsContainer}>
          <Grid item sm={6} xs={6}>
            <ControlledSelectInput
              label="Tipo de cliente"
              name="clientType"
              options={validateOptions}
              control={control}
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.clientType}
              isDirty={Boolean(errors?.clientType)}
            />
          </Grid>
          <Grid item sm={6} xs={6}>
            <ControlledSelectInput
              label="Banco"
              name="financialInstitutionCode"
              options={bankOptions}
              control={control}
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.financialInstitutionCode}
              isDirty={Boolean(errors?.financialInstitutionCode)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={styles.fieldsContainer}>
          <Grid item sm={6} xs={6}>
            <ControlledSelectInput
              label="Tipo de identificación"
              name="identificationType"
              options={identificationTypeOptions}
              control={control}
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.identificationType}
              isDirty={Boolean(errors?.identificationType)}
            />
          </Grid>

          <Grid item sm={6} xs={6}>
            <ControlledTextInput
              label="Número de identificación"
              name="identification"
              control={control}
              isDirty={Boolean(errors?.identification)}
              type="text"
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.identification}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={styles.fieldsContainer}>
          <Grid item sm={12} xs={12}>
            <ControlledTextInput
              label="Nombre del pagador"
              name="fullName"
              control={control}
              isDirty={Boolean(errors?.fullName)}
              type="text"
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.fullName}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={styles.fieldsContainer}>
          <Grid item sm={6} xs={6}>
            <ControlledTextInput
              label="Pais"
              name="phoneCountryCode"
              startAdornment={
                <Box
                  sx={{ display: 'flex', width: '40px', alignItems: 'center' }}
                >
                  <FlagColombia /> &nbsp;
                  <Typography paragraph sx={{ fontSize: '14px', margin: 0 }}>
                    +
                  </Typography>
                </Box>
              }
              control={control}
              isDirty={Boolean(errors?.phoneCountryCode)}
              type="number"
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.phoneCountryCode}
              disabled
            />
          </Grid>

          <Grid item sm={6} xs={6}>
            <ControlledTextInput
              label="Número de celular"
              name="phone"
              control={control}
              isDirty={Boolean(errors?.phone)}
              type="number"
              inputsProps={{
                sx: styles.inputForm,
              }}
              errors={errors.phone}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button
              variant="outlined"
              onSubmit={handleSubmit(onSubmit)}
              sx={styles.buttonOnSubmit}
              type="submit"
              disabled={disabledButtonLink || payCouponMutation?.isPending}
            >
              {payCouponMutation?.isPending ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                'Generar link de pago'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>

      <NotificationModal
        modalState={modalState}
        resetData={resetData}
        walletType={Gateway.Pse}
      />
    </Box>
  );
};

const styles = {
  buttonOnSubmit: {
    height: 55,
    minWidth: 100,
    width: '165px',
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
    background: theme.palette.primary.main,
    color: '#FFF',
    padding: '5px',
    marginLeft: theme.spacing(2),
    borderRadius: '5px',
    ':hover': { backgroundColor: '#FFF', color: theme.palette.primary.main },
    '&:disabled': {
      color: theme.palette.common.white,
      backgroundColor: '#8B8B8B',
    },
  },
  fieldsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  inputForm: {
    width: '100%',
    background: '#fff',
  },
  inputFormPhone: {
    width: '100%',
    background: '#fff',
  },
};
