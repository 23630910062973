import { Box, Button, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import theme from 'ui/styles/theme';
import { PaymentPseForm } from './paymentForms/PaymentPseForm';
import Image from 'next/image';
import PseIcon from '../public/pse.svg';
import { Gateway, ICoupon, ICouponDistributed } from 'recaudo-common';

interface PaymentProcessFormProps {
  walletType: Gateway | null;
  setWalletType: Dispatch<SetStateAction<Gateway | null>>;
  resetContractData: () => void;
  distributionMethod: string | null;
  paymentType: string | null;
  couponData?: ICoupon | ICouponDistributed;
}

export const PaymentProcessForm = (props: PaymentProcessFormProps) => {
  const {
    walletType,
    setWalletType,
    resetContractData,
    distributionMethod,
    couponData,
    paymentType,
  } = props;
  const [modalState, setModalState] = useState<boolean>(false);

  const handleModalstate = (value?: boolean) => {
    setModalState(value || !modalState);
  };

  const resetViewData = () => {
    resetContractData();
    setWalletType(null);
  };

  const selectPaymentMethod = (value: Gateway) => {
    if (value === walletType) {
      setWalletType(null);
      return;
    }
    setWalletType(value);
  };

  return (
    <Box width="100%">
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
        Método de pago
      </Typography>
      <Typography paragraph sx={{ fontSize: '14px' }}>
        Seleccione la billetera que el cliente prefiere para procesar el pago.
      </Typography>

      <Grid container sx={styles.methodsContainer} spacing={4}>
        <Grid item xs={6}>
          <Button
            onClick={() => selectPaymentMethod(Gateway.Pse)}
            startIcon={
              <Image
                src={PseIcon}
                alt="none"
                width={53}
                style={{ marginLeft: '4px' }}
              />
            }
            sx={
              walletType === Gateway.Pse
                ? { ...styles.methodButton, ...styles.selectedButton }
                : styles.methodButton
            }
          >
            Pse
          </Button>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
        {walletType && (
          <Typography paragraph sx={{ fontSize: '14px', fontWeight: '400' }}>
            Datos del cliente
          </Typography>
        )}

        {walletType === Gateway.Pse && (
          <PaymentPseForm
            distributionMethod={distributionMethod}
            paymentType={paymentType}
            handleModalstate={handleModalstate}
            modalState={modalState}
            resetViewData={resetViewData}
            couponData={couponData}
          />
        )}
      </Box>
    </Box>
  );
};

const styles = {
  methodsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  methodButton: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    border: '1px solid',
    borderColor: '#DBDBDB',
    borderRadius: 2,
    backgroundColor: '#F8FBFF',
    padding: 0,
    fontSize: 14,
    textTransform: 'none',
    color: '#333333',
    height: 55,
  },
  icon: { color: '#104EB2' },
  selectedButton: {
    borderColor: '#104EB2',
    backgroundColor: '#F0F6FF',
  },
  buttonSubmit: {
    height: 55,
    maxWidth: 48,
    minWidth: 100,
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
  },
};
