import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import { CancelRounded, CheckCircleRounded } from '@mui/icons-material';
import ControlledTextInput from 'ui/components/inputs/controlled-text-input';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import theme from 'ui/styles/theme';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CustomPaymentType } from '../utils/enums/payment-form.enum';
import { UseMutationResult } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import {
  calculateCurrentProductValue,
  calculateTotalCurrentDebts,
  ContractData,
  countProductsWithCurrentDebt,
  DebtProducts,
  ICoupon,
  ICouponDistributed,
  moneyWithDots,
  PartialPaymentValue,
  ProductType,
} from 'recaudo-common';

const customValueSchema = (maxValue: number) =>
  yup.object({
    customValue: yup
      .string()
      .required('Es requerido')
      .matches(/^\d+$/, 'Solo números')
      .test('positive', 'Debe ser mayor que $0', (val) => {
        const value = Number(val);
        return value > 0;
      })
      .test(
        'gt',
        `El valor debe ser mayor o igual que ${moneyWithDots(PartialPaymentValue.Minimum)}`,
        (val) => {
          return Number(val) >= PartialPaymentValue.Minimum;
        }
      )
      .test(
        'lt',
        `El valor debe ser menor o igual que ${moneyWithDots(maxValue)}`,
        (val) => {
          return Number(val) <= maxValue;
        }
      )
      .typeError('Digite un valor válido'),
  });

const defaultValues = {
  customValue: '',
};

interface CustomValueFormProps {
  distributionMethod: CustomPaymentType | null;
  setDistributionMethod: Dispatch<SetStateAction<CustomPaymentType | null>>;
  contract: ContractData | null;
  debtData: DebtProducts | null;
  createDistributedCouponMutation: UseMutationResult<
    ICouponDistributed,
    unknown,
    { couponValue: number; contractId: number },
    unknown
  >;
  createBrillaCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponValue: number; contractId: number; productId: number },
    unknown
  >;
}

export const CustomPaymentForm = (props: CustomValueFormProps) => {
  const {
    distributionMethod,
    setDistributionMethod,
    contract,
    createDistributedCouponMutation,
    createBrillaCouponMutation,
    debtData,
  } = props;

  const [showDistributionMethod, setShowDistributionMethod] =
    useState<boolean>(false);

  const allowPartialPayment = useMemo(() => {
    if (debtData) {
      const validProducts = [
        ProductType.FinancingServices,
        ProductType.Gas,
        ProductType.BrillaInsurancesA,
      ];
      const areAllowedProducts = debtData.every((product) =>
        validProducts.includes(product.productType)
      );

      const productsCount = countProductsWithCurrentDebt(debtData);
      const isOneProductByType = Object.values(productsCount).every(
        (count) => count === 1
      );

      return areAllowedProducts && isOneProductByType;
    }
  }, [debtData]);

  const onlyGasProduct = useMemo(() => {
    const productsCount = debtData
      ? countProductsWithCurrentDebt(debtData)
      : {};

    return (
      Object.values(productsCount).length === 1 &&
      productsCount[ProductType.Gas] === 1
    );
  }, [debtData]);

  const brillaProductValue = useMemo(() => {
    const brillaProduct = debtData?.find(
      ({ productType }) => productType === ProductType.FinancingServices
    );

    return brillaProduct ? calculateCurrentProductValue(brillaProduct) : 0;
  }, [debtData]);

  const totalCurrentDebtValue = useMemo(
    () => (debtData ? calculateTotalCurrentDebts(debtData) : 0),
    [debtData]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm<{ customValue: string }>({
    resolver: yupResolver(customValueSchema(totalCurrentDebtValue)),
    defaultValues,
    mode: 'all',
  });

  const resetMethod = useCallback(() => {
    setDistributionMethod(null);
    setShowDistributionMethod(false);
  }, [setDistributionMethod]);

  const resetCoupons = useCallback(() => {
    createBrillaCouponMutation.reset();
    createDistributedCouponMutation.reset();
  }, [createBrillaCouponMutation, createDistributedCouponMutation]);

  const resetAll = useCallback(() => {
    reset();
    resetMethod();
    resetCoupons();
  }, [reset, resetMethod, resetCoupons]);

  const generateCoupon = useCallback(
    async (method: CustomPaymentType, value: number) => {
      if (!debtData || debtData?.length === 0) {
        toast.error('No tienes deudas pendientes');
        resetAll();
        return;
      }

      if (method === CustomPaymentType.Distributed) {
        if (value > totalCurrentDebtValue) {
          toast.error('El valor no debe ser mayor que la deuda corriente');
          resetAll();
          return;
        }

        const distributedCouponData = createDistributedCouponMutation.data;
        if (distributedCouponData?.value === value) {
          return;
        }

        await createDistributedCouponMutation.mutateAsync({
          couponValue: value,
          contractId: contract!.contractId,
        });
        return;
      }

      if (method === CustomPaymentType.Brilla) {
        if (value > brillaProductValue) {
          toast.error(
            'El valor no debe ser mayor que la deuda corriente de Brilla'
          );
          resetAll();
          return;
        }

        const brillaProducts = debtData?.filter(
          (product) => product.productType === ProductType.FinancingServices
        );

        if (brillaProducts.length === 0) {
          toast.error('No tienes deuda Brilla');
          resetAll();
          return;
        }

        if (brillaProducts?.length > 1) {
          toast.error('Tienes más de un producto Brilla');
          resetAll();
          return;
        }

        const brillaCouponData = createBrillaCouponMutation.data;
        if (brillaCouponData?.value === value) {
          return;
        }

        await createBrillaCouponMutation.mutateAsync({
          contractId: contract!.contractId,
          couponValue: value,
          productId: brillaProducts[0].productId,
        });
      }
    },
    [
      contract,
      createBrillaCouponMutation,
      createDistributedCouponMutation,
      debtData,
      resetAll,
      brillaProductValue,
      totalCurrentDebtValue,
    ]
  );

  const handleMethodChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const method = event.target.value as CustomPaymentType;
    const couponValue = Number(getValues('customValue'));

    setDistributionMethod(method);

    await generateCoupon(method, couponValue);
  };

  const onSubmit = (data: { customValue: string }) => {
    const customValue = Number(data.customValue);

    let couponValue;
    if (distributionMethod === CustomPaymentType.Brilla) {
      couponValue = createBrillaCouponMutation.data?.value;
    } else if (distributionMethod === CustomPaymentType.Distributed) {
      couponValue = createDistributedCouponMutation.data?.value;
    }

    if (couponValue !== customValue) {
      setDistributionMethod(null);
    }

    if (customValue > 0) {
      setShowDistributionMethod(true);
    }
  };

  useEffect(() => {
    return () => {
      setDistributionMethod(null);
    };
  }, [setDistributionMethod]);

  const loadingCoupon =
    createDistributedCouponMutation.isPending ||
    createBrillaCouponMutation.isPending;

  return (
    <Box sx={{ width: '100%' }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ marginBottom: theme.spacing(4) }}
      >
        <Grid
          container
          sx={{
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={12}>
            <ControlledTextInput
              label="Valor personalizado"
              name="customValue"
              control={control}
              type="text"
              isDirty={Boolean(errors?.customValue)}
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              inputsProps={{
                sx: {
                  flex: 1,
                  width: '100%',
                  paddingRight: theme.spacing(2),
                  background: '#fff',
                },
              }}
              errors={errors.customValue}
              disabled={loadingCoupon}
            />
          </Grid>
          <Grid item container xs={4} sx={{ justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              <Button
                onClick={resetAll}
                sx={{ height: 55 }}
                disabled={loadingCoupon || !isDirty}
              >
                <CancelRounded
                  sx={{ color: loadingCoupon || !isDirty ? 'gray' : '#FE685E' }}
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleSubmit(onSubmit)}
                type="submit"
                sx={{ height: 55 }}
                disabled={
                  loadingCoupon || !isDirty || Boolean(errors.customValue)
                }
              >
                <CheckCircleRounded
                  sx={{
                    color:
                      loadingCoupon || !isDirty || Boolean(errors.customValue)
                        ? 'gray'
                        : '#008824',
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {showDistributionMethod && (
        <FormControl disabled={loadingCoupon}>
          <RadioGroup
            aria-labelledby="distribution-method-radio-buttons-group"
            name="distribution-method-radio-buttons-group"
            row
            value={distributionMethod}
            onChange={handleMethodChange}
          >
            <FormControlLabel
              value={CustomPaymentType.Brilla}
              control={<Radio />}
              label="Abono solo a Brilla"
              sx={{ marginRight: theme.spacing(32) }}
              disabled={brillaProductValue === 0}
            />
            <FormControlLabel
              value={CustomPaymentType.Distributed}
              control={<Radio />}
              label={onlyGasProduct ? 'Abono solo a gas' : 'Abono a factura'}
              disabled={!allowPartialPayment}
            />
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
};
