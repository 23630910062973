import { ContractData, ProductDebtsApi } from 'recaudo-common';
import api from '../api';

export const getContractData = async (contractId: number) => {
  return await api.get<ContractData>(`/contracts/${contractId}`);
};

export const getDebts = async (contractId: number) => {
  return await api.get<ProductDebtsApi>(`/debts/${contractId}`);
};
