// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Button, Box, Modal, Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import ClearIcon from '@mui/icons-material/Clear';
import theme from '../styles/theme';
import { Gateway } from 'recaudo-common';

interface NotificationModalProps {
  modalState: boolean;
  title?: string;
  resetData: () => void;
  walletType: string;
}

export const NotificationModal = (props: NotificationModalProps) => {
  const { modalState, resetData, title, walletType } = props;

  return (
    <Modal open={modalState} onClose={() => resetData()}>
      <Box sx={styles.modalContainer}>
        <Box sx={styles.header}>
          <Typography paragraph sx={{ fontSize: '14px', fontWeight: '600' }}>
            {title}
          </Typography>
          <ClearIcon sx={styles.iconClose} onClick={() => resetData()} />
        </Box>

        <Box sx={styles.content}>
          <VerifiedIcon
            sx={{ color: '#008824', width: '196px', height: '196px' }}
          />
          <Typography paragraph sx={{ fontSize: '20px', fontWeight: '600' }}>
            Link de pago generado exitosamente
          </Typography>

          {walletType === Gateway.Nequi && (
            <Typography
              paragraph
              sx={{ fontSize: '14px', textAlign: 'center' }}
            >
              El cliente recibirá una notificación en su aplicación de Nequi
              para procesar el pago.
            </Typography>
          )}

          {walletType === Gateway.Pse && (
            <Typography
              paragraph
              sx={{ fontSize: '14px', textAlign: 'center' }}
            >
              El cliente recibirá un link de pago a través de WhatsApp o SMS.
            </Typography>
          )}
        </Box>

        <Box sx={styles.footer}>
          <Button
            variant="outlined"
            onClick={() => resetData()}
            sx={styles.buttonStyle}
            type="submit"
          >
            Finalizar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const styles = {
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '476px',
    heigth: '616px',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    boxShadow: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid #eeeeee',
  },
  iconClose: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    ':hover': { cursor: 'pointer' },
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(1),
    borderTop: '1px solid #eeeeee',
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  buttonStyle: {
    height: 55,
    minWidth: 100,
    width: '215px',
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
    background: theme.palette.primary.main,
    color: '#FFF',
    padding: theme.spacing(0.5),
    margin: theme.spacing(2.5),
    borderRadius: '5px',
    ':hover': { backgroundColor: '#FFF', color: theme.palette.primary.main },
  },
};
