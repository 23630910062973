import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { ContractQuerySectionComponent } from '../components/contractQuerySection';
import { ContractDataSectionComponent } from '../components/contractDataSection';
import { ContractPaymentSectionComponent } from '../components/contractPaymentSection';
import { ContractDebtsValuesComponent } from '../components/contractDebtsValues';
import { useMutation } from '@tanstack/react-query';
import { getContractData, getDebts } from '../http/api/contractDebts';

import { ContractData, CouponStatusEnum, DebtProducts } from 'recaudo-common';
import {
  createBrillaCoupon,
  createDistributedCoupon,
  getCoupon,
} from '../http/api/coupons';
import {
  CustomPaymentType,
  PaymentType,
} from '../utils/enums/payment-form.enum';
import { PaymentProcessForm } from '../components/PaymentProcessForm';
import { Gateway } from 'recaudo-common';

const Home: React.FC = () => {
  const [walletType, setWalletType] = useState<Gateway | null>(null);
  const [responseContractData, setResponseContractData] =
    useState<ContractData | null>(null);
  const [responseDebtsData, setResponseDebtsData] = useState<DebtProducts>([]);
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null);
  const [distributionMethod, setDistributionMethod] =
    useState<CustomPaymentType | null>(null);

  /** Obtener informacion del contrato */
  const callGetContractData = useMutation({
    mutationFn: getContractData,
  });

  /** Obtener informacion de la deuda del contrato */
  const callGetDebts = useMutation({
    mutationFn: getDebts,
  });

  const getCouponMutation = useMutation({
    mutationFn: getCoupon,
  });

  const createBrillaCouponMutation = useMutation({
    mutationFn: createBrillaCoupon,
  });

  const createDistributedCouponMutation = useMutation({
    mutationFn: createDistributedCoupon,
  });

  /** Limpia la informacion del contrato para una nueva busqueda */
  const resetContractData = () => {
    setResponseContractData(null);
    setResponseDebtsData([]);
    setPaymentType(null);
  };

  const couponLoading =
    createBrillaCouponMutation?.isPending ||
    createDistributedCouponMutation?.isPending ||
    getCouponMutation?.isPending;

  const showCouponTypePayment = paymentType === PaymentType.Coupon;
  const showCustomTypePayment =
    paymentType === PaymentType.Custom && distributionMethod;

  const showMethodPaymentForm =
    (showCouponTypePayment || showCustomTypePayment) &&
    !couponLoading &&
    (createBrillaCouponMutation?.data ||
      createDistributedCouponMutation?.data ||
      getCouponMutation?.data?.status === CouponStatusEnum.PENDING);

  return (
    <Box height="calc(100vh - 60px)" display="flex" marginTop="60px">
      <Box sx={styles.sideContainer}>
        <Grid
          container
          sx={{ ...styles.contentContainer, ...styles.leftGridContainer }}
        >
          <Box maxWidth="80%" width="100%">
            <ContractQuerySectionComponent
              callGetDebts={callGetDebts}
              callGetContractData={callGetContractData}
              resetContractData={resetContractData}
              setResponseDebtsData={setResponseDebtsData}
              setResponseContractData={setResponseContractData}
            />

            <ContractPaymentSectionComponent
              debtData={responseDebtsData}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              contract={responseContractData}
              getCouponMutation={getCouponMutation}
              createDistributedCouponMutation={createDistributedCouponMutation}
              createBrillaCouponMutation={createBrillaCouponMutation}
              distributionMethod={distributionMethod}
              setDistributionMethod={setDistributionMethod}
            />

            {showMethodPaymentForm && (
              <PaymentProcessForm
                walletType={walletType}
                setWalletType={setWalletType}
                distributionMethod={distributionMethod}
                paymentType={paymentType}
                resetContractData={resetContractData}
                couponData={
                  createDistributedCouponMutation?.data ||
                  createBrillaCouponMutation?.data ||
                  getCouponMutation?.data
                }
              />
            )}
          </Box>
        </Grid>
      </Box>

      <Box sx={styles.sideContainer}>
        <Grid container sx={styles.contentContainer}>
          <Box maxWidth="80%" width="100%">
            <ContractDataSectionComponent
              loading={callGetContractData?.isPending}
              contractData={responseContractData}
            />
            <ContractDebtsValuesComponent
              loading={callGetDebts?.isPending}
              debtsData={responseDebtsData}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;

export const styles = {
  sideContainer: {
    width: '50%',
    overflow: 'auto',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '56px 80px 80px',
    height: 'calc(100% - 60px)',
  },
  leftGridContainer: {
    borderRight: '1px solid #eeeeee',
  },
};
