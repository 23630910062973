import { Box, Button, Grid } from '@mui/material';
import {
  MonetizationOn,
  MonetizationOnOutlined,
  Receipt,
  ReceiptOutlined,
} from '@mui/icons-material';
import { Dispatch, SetStateAction, useMemo } from 'react';
import theme from 'ui/styles/theme';
import { CouponQueryForm } from './CouponQueryForm';
import {
  calculateTotalCurrentDebts,
  ContractData,
  countProductsWithCurrentDebt,
  DebtProducts,
  ICoupon,
  ICouponDistributed,
  PartialPaymentValue,
  ProductType,
} from 'recaudo-common';
import {
  CustomPaymentType,
  PaymentType,
} from '../utils/enums/payment-form.enum';
import { UseMutationResult } from '@tanstack/react-query';
import { CustomPaymentForm } from './CustomPaymentForm';
import { CouponCard } from './CouponCard';

interface PaymentMethodFormProps {
  paymentType: PaymentType | null;
  setPaymentType: Dispatch<SetStateAction<PaymentType | null>>;
  distributionMethod: CustomPaymentType | null;
  setDistributionMethod: Dispatch<SetStateAction<CustomPaymentType | null>>;
  debtData: DebtProducts | null;
  contract: ContractData | null;
  getCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponId: number; contractId?: number },
    unknown
  >;
  createDistributedCouponMutation: UseMutationResult<
    ICouponDistributed,
    unknown,
    { couponValue: number; contractId: number },
    unknown
  >;
  createBrillaCouponMutation: UseMutationResult<
    ICoupon,
    unknown,
    { couponValue: number; contractId: number; productId: number },
    unknown
  >;
}

export const PaymentMethodForm = (props: PaymentMethodFormProps) => {
  const {
    paymentType,
    setPaymentType,
    debtData,
    contract,
    getCouponMutation,
    createDistributedCouponMutation,
    createBrillaCouponMutation,
    distributionMethod,
    setDistributionMethod,
  } = props;

  const currentDebtValue = useMemo(() => {
    if (debtData) {
      return calculateTotalCurrentDebts(debtData);
    }
    return 0;
  }, [debtData]);

  const allowPartialPayment = useMemo(() => {
    if (!debtData) {
      return false;
    }

    if (currentDebtValue < PartialPaymentValue.Minimum) {
      return false;
    }

    const brillaProducts = debtData.filter(
      ({ productType }) => productType === ProductType.FinancingServices
    );

    if (brillaProducts) {
      // Esto deja abonar 100% Brilla, aunque tenga otros productos,
      // pero la opción distribuida sigue bloqueandose para los casos respectivos.
      const currentBrillaProductValue =
        calculateTotalCurrentDebts(brillaProducts);

      const countBrillaProducts = countProductsWithCurrentDebt(brillaProducts);

      const isOneProductByType = Object.values(countBrillaProducts).every(
        (count) => count === 1
      );

      if (currentBrillaProductValue > 0 && isOneProductByType) {
        return true;
      }
    }

    const validProducts = [
      ProductType.FinancingServices,
      ProductType.Gas,
      ProductType.BrillaInsurancesA,
    ];
    const areAllowedProducts = debtData.every((product) =>
      validProducts.includes(product.productType)
    );

    const productsCount = countProductsWithCurrentDebt(debtData);
    const isOneProductByType = Object.values(productsCount).every(
      (count) => count === 1
    );

    return areAllowedProducts && isOneProductByType;
  }, [debtData, currentDebtValue]);

  const handleMethodClick = (value: PaymentType) => {
    getCouponMutation.reset();
    createBrillaCouponMutation.reset();
    createDistributedCouponMutation.reset();
    if (value === paymentType) {
      setPaymentType(null);
      return;
    }
    setPaymentType(value);
  };

  const disabled = !contract;
  const disablePartialPayment = disabled || !allowPartialPayment;

  return (
    <Box width="100%">
      <Grid container sx={styles.methodsContainer} spacing={4}>
        <Grid item xs={6}>
          <Button
            onClick={() => handleMethodClick(PaymentType.Coupon)}
            disabled={disabled}
            startIcon={
              paymentType === PaymentType.Coupon ? (
                <Receipt sx={disabled ? styles.disabledColor : styles.icon} />
              ) : (
                <ReceiptOutlined
                  sx={disabled ? styles.disabledColor : styles.icon}
                />
              )
            }
            sx={
              paymentType === PaymentType.Coupon
                ? { ...styles.methodButton, ...styles.selectedButton }
                : styles.methodButton
            }
          >
            Cupón
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            onClick={() => handleMethodClick(PaymentType.Custom)}
            disabled={disablePartialPayment}
            startIcon={
              paymentType === PaymentType.Custom ? (
                <MonetizationOn
                  sx={
                    disablePartialPayment ? styles.disabledColor : styles.icon
                  }
                />
              ) : (
                <MonetizationOnOutlined
                  sx={
                    disablePartialPayment ? styles.disabledColor : styles.icon
                  }
                />
              )
            }
            sx={
              paymentType === PaymentType.Custom
                ? { ...styles.methodButton, ...styles.selectedButton }
                : styles.methodButton
            }
          >
            Otro valor
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
        {paymentType === PaymentType.Coupon && (
          <CouponQueryForm
            getCouponMutation={getCouponMutation}
            contract={contract}
          />
        )}
        {paymentType === PaymentType.Custom && (
          <CustomPaymentForm
            contract={contract}
            debtData={debtData}
            createDistributedCouponMutation={createDistributedCouponMutation}
            createBrillaCouponMutation={createBrillaCouponMutation}
            distributionMethod={distributionMethod}
            setDistributionMethod={setDistributionMethod}
          />
        )}
      </Box>
      {(paymentType === PaymentType.Coupon ||
        (paymentType === PaymentType.Custom && distributionMethod)) && (
        <CouponCard
          paymentType={paymentType}
          distributionMethod={distributionMethod}
          getCouponMutation={getCouponMutation}
          createBrillaCouponMutation={createBrillaCouponMutation}
          createDistributedCouponMutation={createDistributedCouponMutation}
          loading={
            getCouponMutation.isPending ||
            createDistributedCouponMutation.isPending ||
            createBrillaCouponMutation.isPending
          }
        />
      )}
    </Box>
  );
};

const styles = {
  methodsContainer: {
    display: 'flex',
    marginBottom: theme.spacing(4),
  },
  methodButton: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    border: '1px solid',
    borderColor: '#DBDBDB',
    borderRadius: 2,
    backgroundColor: '#F8FBFF',
    padding: theme.spacing(3, 8),
    fontSize: 14,
    textTransform: 'none',
    color: '#333333',
  },
  icon: { color: '#104EB2' },
  disabledColor: { color: 'gray' },
  selectedButton: {
    borderColor: '#104EB2',
    backgroundColor: '#F0F6FF',
  },
  buttonSubmit: {
    height: 55,
    maxWidth: 48,
    minWidth: 100,
    fontSize: 'small',
    textTransform: 'none',
    fontWeight: '600',
  },
};
