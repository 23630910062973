import axios from 'axios';
import type { ErrorResponse } from 'common-api';
import { ApiError } from 'ui';
import * as Sentry from '@sentry/nextjs';

/**
 * HTTP client to use for making requests to the internal API, this client will have the authentication token injected automatically.
 */
const instance = axios.create({
  baseURL: '/api/',
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const response = error.response;

    if (!response) {
      return error;
    }

    /**
     * When an error occurs in the API call (backend error) we will receive a JSON
     * with details **BUT** when using axios `responseType: 'blob'` the library will
     * parse even these errors in a Blob. In order to receive the error message, code
     * and id we need to check that case and make a JSON.parse from the raw text json
     * response.
     */
    let apiResponse = response.data as ErrorResponse;
    if (apiResponse instanceof Blob) {
      apiResponse = JSON.parse(await apiResponse.text());
    }

    const exception = new ApiError(
      apiResponse.detail,
      apiResponse.errorId,
      apiResponse.appName || 'unknown',
      apiResponse.title,
      {},
      apiResponse.requestId
    );
    Sentry.captureException(exception, {
      tags: {
        errorCode: apiResponse.type,
        appName: apiResponse.appName,
        requestId: apiResponse.requestId,
        errorId: apiResponse.errorId,
      },
    });

    throw exception;
  }
);

export default instance;
