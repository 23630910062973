import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Skeleton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DebtDetailComponent } from './debtsComponents/debtDetail';
import {
  calculateDebtsTotal,
  moneyWithDots,
  DebtProduct,
  calculateTotalCurrentDebts,
  calculateTotalDeferredDebts,
} from 'recaudo-common';
import { useMemo } from 'react';

/**Exporta la seccion de Deudas de la vista de recaudos */
interface ContractDebtsProps {
  debtsData: DebtProduct[];
  loading: boolean;
}

export const ContractDebtsValuesComponent = (props: ContractDebtsProps) => {
  const { loading, debtsData } = props;

  const totalDebt = useMemo(() => {
    return calculateDebtsTotal(debtsData || []);
  }, [debtsData]);

  const currentTotalDebt = useMemo(() => {
    return calculateTotalCurrentDebts(debtsData);
  }, [debtsData]);

  const deferredTotalDebt = useMemo(() => {
    return calculateTotalDeferredDebts(debtsData);
  }, [debtsData]);

  return (
    <Box marginTop="20px">
      <Typography
        sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}
      >
        Información de la deuda
      </Typography>
      <Accordion
        sx={{
          backgroundColor: '#F8FBFF',
          border: '1px solid #DBDBDB',
          ...styles.accordion,
        }}
        style={{ borderRadius: '8px' }}
        disabled={!debtsData?.length}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: '24px',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                Deuda total <br />
              </Typography>
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '14px', width: '25%' }}
                  />
                </>
              ) : (
                <Typography
                  sx={{ fontSize: '16px', textAlign: 'right', fontWeight: 700 }}
                >
                  {moneyWithDots(totalDebt)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: '24px',
                alignItems: 'center',
              }}
            >
              <Typography sx={styles.subtotalText}>
                Subtotal deuda corriente <br />
              </Typography>
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '10px', width: '25%' }}
                  />
                </>
              ) : (
                <Typography sx={styles.subtotalValue}>
                  {moneyWithDots(currentTotalDebt)}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingLeft: '24px',
                alignItems: 'center',
              }}
            >
              <Typography sx={styles.subtotalText}>
                Subtotal deuda diferida <br />
              </Typography>
              {loading ? (
                <>
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '10px', width: '25%' }}
                  />
                </>
              ) : (
                <Typography sx={styles.subtotalValue}>
                  {moneyWithDots(deferredTotalDebt)}
                </Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          {debtsData?.map((product: DebtProduct, index: number) => (
            <DebtDetailComponent key={index} product={product} />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const styles = {
  subtotalText: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#808080',
  },
  subtotalValue: {
    fontSize: '12px',
    textAlign: 'right',
    fontWeight: 700,
  },
  accordion: {
    '.MuiAccordionSummary-root': {
      borderBottom: '1px solid #DBDBDB',
    },
    '.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '.MuiList-root': {
      padding: 0,
    },
    '.MuiAccordionSummary-content': {
      flexDirection: 'column',
    },
  },
};
