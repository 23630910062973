import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { moneyWithDots, CurrentDebt } from 'recaudo-common';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

interface ConceptsProps {
  currentConcepts: CurrentDebt[];
}

export const CurrentConceptsDetailComponent = (props: ConceptsProps) => {
  const { currentConcepts } = props;

  const currentValue = useMemo(() => {
    return currentConcepts?.reduce(
      (account, current) => account + current?.value,
      0
    );
  }, [currentConcepts]);

  return (
    <>
      <Accordion
        sx={{
          backgroundColor: '#fff',
          minHeight: '20px',
          ...styles.accordionDetails,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={styles.valuesBox}>
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
              Valor corriente
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 600, textAlign: 'right' }}
            >
              {moneyWithDots(currentValue)}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: '#fff' }}>
          <Box
            sx={{
              display: 'flex',
              maxHeight: '300px',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            {currentConcepts?.map((concept: CurrentDebt, index: number) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '16px 24px 0 36px',
                  borderBottom: '1px solid #DBDBDB',
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                    Concepto: {concept?.conceptId}
                    <br />
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {capitalize(concept?.conceptDescription || '')} <br />
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                    Saldo pendiente <br />
                  </Typography>
                  <Typography sx={{ fontSize: '12px', textAlign: 'right' }}>
                    {moneyWithDots(concept?.value || 0)} <br />
                    <br />
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export const styles = {
  valuesBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fff',
    padding: '10px 10px 10px 24px',
  },
  accordionDetails: {
    '.MuiAccordionDetails-root': {
      padding: 0,
    },
  },
};
