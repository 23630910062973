import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CurrentConceptsDetailComponent } from './currentDetails';
import { DeferredConceptsDetailComponent } from './deferredDetails';
import {
  calculateTotalProductValue,
  DebtProduct,
  moneyWithDots,
} from 'recaudo-common';

interface ContractDebtsProps {
  product: DebtProduct;
}

export const DebtDetailComponent = (props: ContractDebtsProps) => {
  const { product } = props;
  return (
    <Accordion
      sx={{ backgroundColor: '#F0F6FF', minHeight: '20px' }}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box paddingLeft="24px">
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>
              Producto <br />
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
              {product?.productTypeDescription || ''} -{' '}
              {product?.productId || ''}
              <br />
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: '12px', color: 'gray' }}>
              Saldo pendiente <br />
            </Typography>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 700, textAlign: 'right' }}
            >
              {moneyWithDots(calculateTotalProductValue(product))}
              <br />
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0, backgroundColor: '#fff' }}>
        <List>
          {product?.currents && (
            <CurrentConceptsDetailComponent
              currentConcepts={product?.currents}
            />
          )}
          {product?.deferreds && (
            <DeferredConceptsDetailComponent
              deferredConcepts={product?.deferreds}
            />
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export const styles = {
  valuesBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: '#fff',
    padding: '0 10px',
  },
};
